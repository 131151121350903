

import {
  Routes,
  Route,
  
} from "react-router-dom";
import Ai from "./com/ai";
import Auth from "./com/auth";
import Chat from "./com/chat";
import Home from "./com/home";

function App() {

  return (

<Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/auth" element={<Auth />}></Route>
      <Route path="/chat" element={<Chat />}></Route>
      <Route path="/ai" element={<Ai />}></Route>
      </Routes>
  );
}

export default App;